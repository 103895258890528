
import { Box, HStack,VStack,Text } from "@chakra-ui/react";
import Layout from "../components/layout";
import { FastMenuProp } from "../components/fastMenu";
import { ChevronRightIcon } from '@chakra-ui/icons'
import Link from "next/link";
import BrandIcon from "../components/BrandIcon";


const data:FastMenuProp[] = [
    {href: '/relations', text:'Relaties', iconClassName: 'fi fi-rr-User'},
    {href: '/incomminginvoice', text:'Inkoopfacturen', iconClassName: 'fi fi-rr-Shopping-cart'},
    {href: '/quotation', text:'Offertes', iconClassName: 'fi fi-rr-Edit'},
    {href: '/invoice', text:'Facturen', iconClassName: 'fi fi-rr-Copy-alt'},
    {href: '/card/edit', text:'AIMCard', iconClassName: 'fi fi-rr-Credit-card'},
]



export default () => {
    return (
        <Layout heading='Dashboard' >
            <Box height={5}></Box>
            <VStack w='full' >
                {data.map(item => (
                    <Link href={item.href} >
                        <Box w='full' bg='white' borderRadius={'xl'} p={1}  >
                            <HStack w='full' justifyContent={'space-between'} p={4} borderRadius={'xl'} _hover={{bg:'gray.50'}} >
                                <HStack ml={2} spacing={6} >
                                    <BrandIcon color='#000563' width={5} height={5} iconClassName={item.iconClassName} />
                                    <VStack w='full' >
                                        <Text w='full' color='#000563' fontWeight='bold' >{item.text}</Text>
                                    </VStack>
                                </HStack>
                                <ChevronRightIcon />
                            </HStack>
                        </Box>
                    </Link>
                ))}
            </VStack>
        </Layout>
    )
}