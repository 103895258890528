
export type IconTypes = 
    |   "sign-out"
    |   "add"
    |   'alarm-clock'
    |   'align-center'
    |   'align-justify'
    |   'align-left'
    |   'align-right'
    |   'ambulance'
    |   'angle-double-left'
    |   'angle-double-right'
    |   'angle-double-small-left'
    |   'angle-double-small-right'
    |   'angle-down'
    |   'angle-left'
    |   'angle-right'
    |   'angle-small-down'
    |   'angle-small-left'
    |   'angle-small-right'
    |   'angle-small-up'
    |   'angle-up'
    |   'apps'
    |   'apps-add'
    |   'apps-delete'
    |   'apps-sort'
    |   'archive'
    |   'arrow-down'
    |   'arrow-left'
    |   'arrow-right'
    |   'arrow-up'
    |   'arrow-down'
    |   'arrow-small-down'
    |   'arrow-small-left'
    |   'arrow-small-right'
    |   'arrow-small-up'
    |   'arrow-small-down'
    |   'asterik'
    |   'document-signed'
    |   'fi fi-rr-User'
    |   'fi fi-rr-Folder'
    |   'fi fi-rr-Credit-card'
    |   'fi fi-rr-Paper-plane'
    |   'fi fi-rr-Layout-fluid'
    |   'fi fi-rr-Settings'
    |   'fi fi-rr-Browser'
    |   'fi-rr-download'
    |   'fi-rr-share'
    |   'fi-rr-envelope'
    |   'fi-rr-pencil'
    |   'fi-rr-angle-down'
    |   'fi fi-rr-Shopping-cart'
    |   'fi fi-rr-Edit'
    |   'fi fi-rr-Copy-alt'
    |   'fi fi-rr-Grid'
    |   'fi fi-rr-Expand'
    |   'fi fi-rr-Eye'
    |   'fi fi-rr-Shuffle'
    |   'fi fi-rr-Menu-dots'
    |   string

export default (props:{
    width?:string | number,
    height?:string | number,
    iconClassName:IconTypes,
    color?:string
    }) =>  {
    const style = {
        color: props.color,
        display: 'inline-flex',
        width: (props.width) ? props.width : '100%',
        height: (props.height) ? props.height : '100%',
        'justifyContent': 'center',
        'alignItems': 'center'
        
    }
    if(props.iconClassName.includes('fi fi-rr-')) return ( <i style={style} className={props.iconClassName.toLowerCase()}></i> )
    else if(props.iconClassName.includes('fi-sr-')) return ( <i style={style} className={props.iconClassName}></i> )
    else if(props.iconClassName.includes('fi-rr-')) return ( <i style={style} className={props.iconClassName}></i> )
    return ( <i style={style} className={'fi-rr-'+props.iconClassName}></i> )
}